import { Link } from "gatsby";
import React from "react";
import styled from "styled-components"
import { Caption2, SmallText } from "../styles/TextStyles";

export default function PurchaseButton(props){
    const {title, subtitle} = props
    return(
        <Link to="page-2">
            <Wrapper>
                <IconWrapper>
                <Icon src="/images/icons/credit.svg" />
                <Ring src="/images/icons/icon-ring.svg" />
                </IconWrapper>
                <TextWrapper>
                <Title>{title || "Get Pro Access"}</Title>
                <Subtitle>{subtitle || "$19 per month"}</Subtitle>
                </TextWrapper>
            </Wrapper>
        </Link>
        )
    }

const Wrapper = styled.div`
    width: 280px;
    height: 77px;
    padding: 12px;
    background: linear-gradient(180deg, #FFFFFF 0%, #D9DFFF 100%);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 20px 40px rgba(23, 0, 102, 0.2), inset 0px 0px 0px 0.5px rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    display: grid;
    grid-template-columns: 53px auto;
    align-items: center; // centered everything perfectly
    gap: 20px;
    
    *, 
    &{ //asterisk selects all child properties. USE WISELY, and is self
        transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    :hover{
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 30px 60px rgba(23, 0, 102, 0.5), inset 0px 0px 0px 0.5px rgba(255, 255, 255, 0.5);
        transform: translateY(-3px); //To make the button float
    }
`

const TextWrapper = styled.div`
    display: grid;
    gap: 4px;
`

const Title = styled(Caption2)`
    colour: black;
`

const Subtitle = styled(SmallText)`
    color: black;
    opacity: 0.7;
`

const Icon = styled.img`
    width: 29px;
    height: 29px;
`

const Ring = styled.img`
    position: absolute; //Absolute aligns against the entire browser container
    top: -15px;
    left: -16px;

    ${Wrapper}:hover & { // dollar sign allows you to reference anything on top, and is used to say use this property for self so in this case, ring.
        transform: rotate(30deg) scale(1.2) translate(1px, 1px);
    }
`

const IconWrapper = styled.div`
    width: 45px;
    height: 45px;
    background: linear-gradient(200.44deg, #4316DB 13.57%, #9076E7 98.38%);
    //box-shadow: 0px 10px 20px rgba(182, 153, 255, 0.3); //Done by me from Figma. Removed as it was messign the alignment of the ring
    border-radius: 50%;
    display: grid;
    justify-content: center; // justify means horizontally in CSS Grid. Use start for left, center for middle and end for right.
    align-content: center; //Aligns it vertically
    justify-self: center; //This applies just to the column within the container. With this, you can set alignment for each column inside the container. In this caption-side, this is column 1 inside the wrapper
    position: relative; //This makes the position absolute on top (ring) find the closest container

    ${Wrapper}:hover & {
        filter: hue-rotate(10deg) brightness(150%) saturate(120%);
    }
`
